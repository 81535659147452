var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("swich-button", {
    class: _vm.data.class,
    attrs: { value: _vm.data.value, disabled: _vm.data.disabled },
    on: { input: _vm.input },
    nativeOn: {
      click: function ($event) {
        $event.stopPropagation()
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }